import { SETCATEGORY } from "../ActionTypes/categoryTypes"

const initialState = { category: "All" };

const categoryReducer=(state=initialState, action)=>{

    switch (action.type) {
        
        case SETCATEGORY : return {...state , category : action.payload}
    
        default:
            return state
    }

}

export default categoryReducer