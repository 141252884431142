import React from 'react';

const Hero = () => {
  return (
    <main className="bg-gray-50 py-16">
      <div className="container mx-auto px-8">
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          {/* Image Section */}
          <div className="w-full md:w-1/3 flex justify-center">
            <img
              style={{ height: '300px', width: 'auto' }}
              src="https://i.ibb.co/DHj7Cbq1/rf-removebg-preview.png"
              alt="RFID Technology Illustration"
              className="rounded-lg transform hover:scale-105 transition-transform duration-300"
            />
          </div>

          {/* Text Section */}
          <div className="w-full md:w-2/3 space-y-8 text-center md:text-left">
            <h1 className="mb-4 text-4xl font-extrabold md:text-5xl lg:text-6xl bg-gradient-to-r from-[#00a7b9] via-[#f35a37] to-[#075485] bg-clip-text text-transparent">
              RFIDIA: Leader de la traçabilité RFID, IoT et IA Générative
            </h1>
            <p className="text-lg text-gray-600">
            Optimisez vos processus et prenez des décisions éclairées plus rapidement.
            </p>
            <div className="flex flex-col md:flex-row gap-4">
              <a
                href="#services"
                className="bg-[#075485] text-white px-8 py-3 rounded-lg font-semibold hover:bg-[#00a7b9] transition-colors duration-300"
              >
                Our Services
              </a>
              <a
                href="/Contact"
                className="bg-transparent border-2 border-[#f35a37] text-[#f35a37] px-8 py-3 rounded-lg font-semibold hover:bg-[#f35a37] hover:text-white transition-colors duration-300"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Hero;