"use client";

import { Button, Card, Table } from "flowbite-react";

const OrdersCard = ({ el }) => {
  const items = el.purchasedItems;

  return (
    <Card className="max-w-4xl">
      <div>
        <h1 className="text-lg font-bold mb-2">Purchased Items</h1>
        <div className="overflow-x-auto">
          <Table>
            <Table.Head>
              <Table.HeadCell>Product Name</Table.HeadCell>
              <Table.HeadCell>Price</Table.HeadCell>
              <Table.HeadCell>Quantity</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {items.map((item, index) => (
                <Table.Row
                  key={index}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {item.name}
                  </Table.Cell>
                  <Table.Cell>${item.price.toFixed(2)}</Table.Cell>
                  <Table.Cell>{item.quantity}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            
          </Table>
        </div>
      </div>
      <h2>{el.totalPrice}</h2>
      <Button className="mt-4">Annuler la commande</Button>
    </Card>
  );
};

export default OrdersCard;
