"use client";
import { Navbar, Dropdown } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Use useNavigate for routing
import { setCategory } from "../Redux/Actions/categoryActions";

const Nav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // React Router navigation hook
  const token = localStorage.getItem("token");
  const user = useSelector((state) => state.userReducer.user);

  const handleCategoryClick = (category) => {
    dispatch(setCategory(category)); // Update Redux state
    navigate("/Products"); // Navigate to Products page
  };

  return (
    <Navbar fluid className="bg-[#075485] border-b-4 border-[#00a7b9] shadow-lg">
      <div className="container mx-auto px-8 flex justify-between items-center">
        {/* Left Section: Brand and Navigation Links */}
        <div className="flex items-center">
          <Navbar.Brand href="/">
            <img
              src="https://i.ibb.co/DHj7Cbq1/rf-removebg-preview.png"
              className="mr-3 h-8 sm:h-12"
              alt="RFIDIA Logo"
            />
            <span className="self-center whitespace-nowrap text-2xl font-bold text-white">
              RFIDIA
            </span>
          </Navbar.Brand>

          <Navbar.Collapse className="ml-8 hidden md:flex">
            <Navbar.Link
              href="/"
              className="text-white hover:text-[#f35a37] transition-colors duration-300"
            >
              Home
            </Navbar.Link>
            <Dropdown
              label="Products"
              inline
              className="bg-[#075485] border border-[#00a7b9] shadow-lg rounded-lg mt-2 w-48"
              renderTrigger={() => (
                <Navbar.Link
                  href="#"
                  className="text-white hover:text-[#f35a37] transition-colors duration-300"
                >
                  Products
                </Navbar.Link>
              )}
            >
              {["All", "Antenne", "Etiquette", "Mobile", "NFC", "Reader", "Tag"].map((category) => (
                <Dropdown.Item
                  key={category}
                  onClick={() => handleCategoryClick(category.toUpperCase())}
                  className="text-white hover:bg-[#00a7b9] hover:text-black transition-colors duration-300"
                >
                  {category}
                </Dropdown.Item>
              ))}
            </Dropdown>
            <Navbar.Link
              href="/Solutions"
              className="text-white hover:text-[#f35a37] transition-colors duration-300"
            >
              Solutions
            </Navbar.Link>
            <Navbar.Link
              href="/Services"
              className="text-white hover:text-[#f35a37] transition-colors duration-300"
            >
              Training
            </Navbar.Link>
            <Navbar.Link
              href="/Consulting"
              className="text-white hover:text-[#f35a37] transition-colors duration-300"
            >
              Consulting
            </Navbar.Link>
            <Navbar.Link
              href="/Contact"
              className="text-white hover:text-[#f35a37] transition-colors duration-300"
            >
              Contact
            </Navbar.Link>
          </Navbar.Collapse>
        </div>

        {/* Right Section: Login/Logout Button */}
        <div className="flex items-center">
          {token ? (
            <div className="flex items-center gap-4">
              <span className="text-white font-semibold">
                Welcome, {user?.name}
              </span>
              <button
                onClick={() => {
                  localStorage.removeItem("token");
                  window.location.reload();
                }}
                className="bg-[#f35a37] text-white px-4 py-2 rounded-lg hover:bg-[#b63123] transition-colors duration-300"
              >
                Logout
              </button>
            </div>
          ) : (
            <a
              href="/login"
              className="bg-[#00a7b9] text-white px-6 py-2 rounded-lg font-semibold hover:bg-[#075485] transition-colors duration-300"
            >
              Login
            </a>
          )}
          <Navbar.Toggle className="text-white hover:text-[#f35a37] ml-4 md:hidden" />
        </div>
      </div>

      {/* Mobile Menu (Collapsed Links) */}
      <Navbar.Collapse className="md:hidden mt-4">
        <Navbar.Link
          href="/"
          className="text-white hover:text-[#f35a37] transition-colors duration-300"
        >
          Home
        </Navbar.Link>
        <Navbar.Link
          href="/Products"
          className="text-white hover:text-[#f35a37] transition-colors duration-300"
        >
          Products
        </Navbar.Link>
        <Navbar.Link
          href="/Solutions"
          className="text-white hover:text-[#f35a37] transition-colors duration-300"
        >
          Solutions
        </Navbar.Link>
        <Navbar.Link
          href="/Services"
          className="text-white hover:text-[#f35a37] transition-colors duration-300"
        >
          Training
        </Navbar.Link>
        <Navbar.Link
          href="/Sauvegarde"
          className="text-white hover:text-[#f35a37] transition-colors duration-300"
        >
          Consulting
        </Navbar.Link>
        <Navbar.Link
          href="/Contact"
          className="text-white hover:text-[#f35a37] transition-colors duration-300"
        >
          Contact
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Nav;
