import React from 'react';

const Consulting = () => {
  return (
    <div className="p-10 bg-white dark:bg-gray-900">
      {/* Section: Services de conseil et de mise en œuvre */}
      <div className="mb-12">
        <h1 className="text-[#065587] dark:text-[#00acb9] text-4xl font-bold mb-6">Services de conseil et de mise en œuvre de projet de traçabilité RFID</h1>
        <div className="space-y-4 text-gray-600 dark:text-gray-400 leading-8">
          <p>
            Chez RFIDIA, notre mission est d'offrir des services de conseil et d'analyse de premier ordre, axés exclusivement sur la mise en œuvre et l'optimisation de solutions RFID pour votre entreprise.
          </p>
          <p>
            En collaborant avec nous, vous bénéficierez de notre expertise approfondie dans l’adaptation des technologies RFID à diverses applications, qu'il s'agisse de l'optimisation de la chaîne d'approvisionnement, de l'amélioration de l'efficacité des processus métier ou du suivi en temps réel des actifs.
          </p>
          <p>
            Notre approche repose sur une compréhension approfondie de vos défis et objectifs commerciaux afin de recommander et de mettre en œuvre des solutions RFID ayant un impact concret, notamment l’accélération des flux de travail, l’amélioration de la prise de décision basée sur les données et l’augmentation de la rentabilité.
          </p>
          <p>
            Notre équipe d'experts RFID évalue les besoins spécifiques de votre entreprise, recommande le matériel et les logiciels les plus adaptés, supervise l’installation et l’intégration des systèmes RFID, et assure une formation ainsi qu’un accompagnement continu. Grâce à leur expertise, nos consultants aident les organisations à améliorer la précision, la visibilité et le contrôle de leurs processus.
          </p>
          <p>
            Notre offre se divise en trois domaines : l’évaluation, la mise en œuvre et la gestion de projet à long terme.
          </p>
        </div>
      </div>

      {/* Section: Études de site */}
      <div className="mb-12">
        <h1 className="text-[#065587] dark:text-[#00acb9] text-4xl font-bold mb-6">ÉTUDES DE SITE</h1>
        <div className="space-y-4 text-gray-600 dark:text-gray-400 leading-8">
          <p>
            Étant donné que la technologie RFID dépend de l'environnement, une étude de site RFID peut considérablement améliorer le taux de réussite de tout projet. En analysant les processus métier et en comprenant les exigences techniques dès le départ, il est possible d’élaborer une conception détaillée du projet RFID.
          </p>
          <p>
            De plus, en documentant dès le début tous les facteurs environnementaux susceptibles d'influencer le projet, il devient plus facile de sélectionner les composants les plus adaptés, qu'il s'agisse du matériel, des étiquettes ou des logiciels RFID.
          </p>
          <p>
            RFIDIA réalise un audit complet de votre environnement afin de vous proposer la solution de traçabilité RFID la plus adaptée à vos besoins. Nous nous déplaçons sur vos sites pour analyser vos processus existants et prendre en compte vos contraintes spécifiques.
          </p>
        </div>
      </div>

      {/* Section: Notre audit comprend */}
      <div className="mb-12">
        <h2 className="text-[#f15b39] text-2xl font-bold mb-6">Notre audit comprend :</h2>
        <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 space-y-4 leading-8">
          <li>Une visite complète de votre installation et de vos locaux.</li>
          <li>Une étude détaillée de vos processus internes (fabrication, flux logistiques, inventaire).</li>
          <li>La détermination du type de tags RFID adéquats pour vos besoins en traçabilité.</li>
          <li>La définition du type et du nombre de lecteurs et d’antennes ainsi que leur positionnement.</li>
          <li>Une étude d’intégration des systèmes RFID dans votre environnement mécanique ou technique.</li>
          <li>Une analyse des passerelles d’échange de données avec vos automatismes et votre système d’information.</li>
          <li>Une évaluation de vos systèmes actuels et de leur compatibilité avec la solution RFID que nous proposons, avec une approche flexible.</li>
          <li>Une analyse de l’adéquation de la technologie RFID à votre site et à vos besoins, accompagnée d’une étude coûts-bénéfices et du calcul du retour sur investissement. Nous examinerons comment maximiser les avantages de la RFID, notamment les économies de coûts, la réduction des déchets et l’amélioration des flux de travail.</li>
          <li>Une évaluation approfondie avant la conception, afin d’identifier les faiblesses de votre système actuel et d’y remédier dans notre proposition. Notre objectif est d’optimiser votre système RFID pour révolutionner votre entreprise, vous faire économiser de l’argent et réduire le gaspillage.</li>
          <li>Un plan d’action clair pour la mise en œuvre de la RFID, incluant la cartographie de l'architecture des données, la création d’un flux transparent de données précises, et l’intégration des systèmes actuels et nouveaux.</li>
          <li>Un rapport d’évaluation complet après l’audit du site, détaillant nos conclusions, recommandations et une feuille de route pour l’implémentation de la RFID. Ce rapport personnalisé vous apportera des informations exploitables pour optimiser vos opérations, réduire les coûts, améliorer la productivité et atteindre vos objectifs commerciaux.</li>
        </ul>
      </div>

      {/* Section: Mise en œuvre */}
      <div className="mb-12">
        <h2 className="text-[#f15b39] text-2xl font-bold mb-6">Mise en œuvre</h2>
        <h3 className="text-[#065587] dark:text-[#00acb9] text-xl font-bold mb-4">Mise en place d’un démonstrateur RFID</h3>
        <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 space-y-4 leading-8">
          <li>
            Afin de valider la faisabilité d’un projet RFID, il est essentiel de réaliser un démonstrateur ou POC (Proof of Concept) dans votre environnement. Cette étape présente plusieurs avantages : elle permet de tester la lecture et la recherche d’une étiquette RFID dans votre environnement de production ou de stockage.
          </li>
          <li>
            La réalisation d’un démonstrateur permet également d’établir un cahier des charges précis ou d’ajuster l’expression des besoins et le budget en fonction des résultats des tests.
          </li>
        </ul>
      </div>

      {/* Section: Accompagnement */}
      <div className="mb-12">
        <h3 className="text-[#065587] dark:text-[#00acb9] text-xl font-bold mb-4">Accompagnement</h3>
        <div className="space-y-4 text-gray-600 dark:text-gray-400 leading-8">
          <p>
            Nous vous accompagnons dans toutes les étapes de votre projet RFID, de la phase de conception à la mise en œuvre complète. Nous travaillons en étroite collaboration avec vos équipes pour garantir que notre solution RFID s’adapte parfaitement à vos besoins et à votre organisation.
          </p>
          <p>
            Dès le démarrage du projet, un membre de notre équipe est dédié au pilotage, en lien direct avec nos experts techniques et commerciaux. Cette approche garantit une communication fluide et une grande réactivité tout au long du projet.
          </p>
          <p>
            Contactez-nous dès maintenant via notre formulaire de contact ci-dessous pour concrétiser votre projet RFID !
          </p>
        </div>
      </div>
    </div>
  );
};

export default Consulting;