"use client";


import Activity from "./Activity";
import Hero from "./Hero";
import SimpleGrowAbout from "./SimpleGrowAbout";

import WhyConfidoo from "./WhyConfidoo";


const Home = () => {
  return (

    <div>
      <div>
      <Hero></Hero>
      </div>               
    <SimpleGrowAbout/>
    
    <Activity></Activity>
    



  
    </div>
  )
}

export default Home