import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Snackbar,
  CircularProgress,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";

const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: "#008B8B",
  color: "#ffffff",
  padding: theme.spacing(6, 0),
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "linear-gradient(45deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.4) 100%)",
    zIndex: 1,
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
}));

const SocialButton = styled(IconButton)(({ theme }) => ({
  color: "#ffffff",
  margin: theme.spacing(0, 1),
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-3px)",
    
  },
}));

const FooterLink = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  transition: "color 0.3s ease",
  "&:hover": {
    
  },
}));

const NewsletterForm = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
}));

const GlobalFooter = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setOpenSnackbar(true);
      setEmail("");
    } catch (error) {
      console.error("Subscription failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const links = [
    { title: "About Us", items: ["Our Story", "Team", "Careers", "Press"] },
    { title: "Services", items: ["Web Design", "Development", "Marketing", "Consulting"] },
    { title: "Support", items: ["FAQ", "Contact", "Knowledge Base", "Community"] },
  ];

  return (
    <StyledFooter>
      <ContentWrapper>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {links.map((section, index) => (
              <Grid item xs={12} sm={6} md={2} key={index}>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {section.title}
                </Typography>
                {section.items.map((item, idx) => (
                  <FooterLink variant="body2" display="block" mb={1} key={idx}>
                    {item}
                  </FooterLink>
                ))}
              </Grid>
            ))}

            <Grid item xs={12} sm={6} md={6}>
              <NewsletterForm component="form" onSubmit={handleSubscribe}>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Subscribe to Our Newsletter
                </Typography>
                <Typography variant="body2" mb={2}>
                  Stay updated with our latest news, updates, and exclusive offers!
                </Typography>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={12} sm={8}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      type="email"
                      aria-label="Email subscription input"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "rgba(255, 255, 255, 0.1)",
                          color: "#ffffff",
                          "& fieldset": {
                            borderColor: "rgba(255, 255, 255, 0.3)",
                          },
                          "&:hover fieldset": {
                            borderColor: "rgba(255, 255, 255, 0.5)",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={loading}
                      sx={{
                        height: "100%",
                        minHeight: "56px",
                        position: "relative",
                      }}
                    >
                      {loading ? <CircularProgress size={24} /> : "Subscribe"}
                    </Button>
                  </Grid>
                </Grid>
              </NewsletterForm>
            </Grid>
          </Grid>

          <Box mt={6} pt={6} borderTop="1px solid rgba(255, 255, 255, 0.1)">
            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
                  © 2024 Your Company. All rights reserved.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent={isMobile ? "center" : "flex-end"}>
                  <SocialButton aria-label="Facebook">
                    <FaFacebookF />
                  </SocialButton>
                  <SocialButton aria-label="Twitter">
                    <FaTwitter />
                  </SocialButton>
                  <SocialButton aria-label="Instagram">
                    <FaInstagram />
                  </SocialButton>
                  <SocialButton aria-label="LinkedIn">
                    <FaLinkedinIn />
                  </SocialButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </ContentWrapper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Thank you for subscribing to our newsletter!
        </Alert>
      </Snackbar>
    </StyledFooter>
  );
};

export default GlobalFooter;
