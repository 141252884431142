import { GETALLORDERS, GETUSERORDERS } from "../ActionTypes/ordersTypes"

const initialState = {
    orders : [],
    userOrders :[]
}

const ordersReducer=(state =initialState , action)=>{

    switch (action.type) {

        case GETALLORDERS : return {...state , orders : action.payload}
        case GETUSERORDERS : return{...state , userOrders : action.payload}
     
    
        default: return state
    }
    

}

export default ordersReducer