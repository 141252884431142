"use client";

import React, { useState, useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { Button, Modal } from "flowbite-react";
import { createOrder } from '../Redux/Actions/ordersActions';

const ShoppingCart = () => {

  const user = useSelector(state=>state.userReducer.user)
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

 
  const [cartItems, setCartItems] = useState(() => {
    const storedCart = localStorage.getItem('Cart');
    const parsedCart = storedCart ? JSON.parse(storedCart) : [];
    
    
    return parsedCart.map(item => ({ ...item, quantity: 1 }));
  });


  useEffect(() => {
    
    localStorage.setItem('Cart', JSON.stringify(cartItems));
  }, [cartItems]);



  const onCloseModal = () => {
    setOpenModal(false);
    setEmail('');
  };

  
  const orderItems = cartItems.map(({ name, price, quantity }) => ({
    name,
    price,
    quantity,
  }));


  const updateQuantity = (id, quantity) => {
    const updatedItems = cartItems.map((item) =>
      item.id === id ? { ...item, quantity: Math.max(1, quantity) } : item
    );
    setCartItems(updatedItems); 
  };

  const removeItem = (id) => {
    const updatedItems = cartItems.filter((item) => item.id !== id);
    setCartItems(updatedItems); 
  };

  
  
  
  const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);

  return (
    <div className="container mx-auto p-4 bg-gray-100 ">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Your Shopping Cart</h1>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '50px', justifyContent: 'space-around' }}>
        {cartItems.length === 0 ? (
        <p className="text-center text-lg text-gray-600">You do not have any items in your cart.</p>
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '50px', justifyContent: 'space-around' }}>
          {cartItems.map((item) => (
            <div key={item.id} style={{ width: '345px', height: '420px' }} className="p-4 border rounded-lg shadow-lg bg-white transition duration-300 ease-in-out transform hover:scale-105">
              <img className="h-52 object-contain"  src={item.image} alt={item.name} />
              <h2 className="text-xl font-semibold mb-2 text-gray-800">{item.name}</h2>
              <p className="text-gray-600 mb-3">{item.description}</p>
              <div className="flex justify-between items-center mb-3">
                <span className="text-lg font-bold text-green-600">${item.price.toFixed(2)}</span>
                <div className="flex items-center">
                  <button
                    className="px-2 py-1 border rounded bg-gray-200 hover:bg-gray-300 transition duration-200"
                    onClick={() => updateQuantity(item.id, item.quantity - 1)}
                    aria-label="Decrease quantity"
                  >
                    -
                  </button>
                  <span className="mx-2 font-semibold">{item.quantity}</span>
                  <button
                    className="px-2 py-1 border rounded bg-gray-200 hover:bg-gray-300 transition duration-200"
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                    aria-label="Increase quantity"
                  >
                    +
                  </button>
                </div>
              </div>
              <button
                onClick={() => removeItem(item.id)}
                className="flex items-center text-red-600 hover:text-red-800 transition duration-200"
                aria-label="Remove item"
              >
                <FaTrashAlt className="mr-2" /> Remove
              </button>
            </div>
          ))}
        </div>
      )}
      </div>

      <div className="mt-8 p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Order Summary</h2>
        
        
        <div className="mb-2 flex justify-between text-gray-700">
          
        </div>
        <div className="mt-4 pt-4 border-t border-gray-200 font-bold flex justify-between text-lg">
          <span>Total</span>
          <span>${total}</span>
        </div>
      </div>

      <div className="mt-8 flex flex-col sm:flex-row justify-between">
        <button onClick={() => navigate('/Products')} className="px-6 py-3 bg-gray-300 rounded-lg hover:bg-gray-400 transition duration-200 ease-in-out text-gray-800 font-semibold mb-4 sm:mb-0">
          Continue Shopping
        </button>
        <button onClick={() => setOpenModal(true)} className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200 ease-in-out font-semibold">
          Proceed to Checkout
        </button>
      </div>

      <Modal show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Confirm Your Order</h3>
            <div className="space-y-4">
              {cartItems.map((item) => (
                <div key={item.id} className="flex justify-between text-gray-700">
                  <span>{item.name} (x{item.quantity})</span>
                  <span>${(item.price * item.quantity).toFixed(2)}</span>
                </div>
              ))}
            </div>

            
            <div className="flex justify-between text-gray-700">
              
            </div>
            <div className="mt-4 pt-4 border-t border-gray-200 font-bold flex justify-between text-lg">
              <span>Total</span>
              <span>${total}</span>
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="mt-2 w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                className="mt-2 w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your phone number"
              />
            </div>

            <div className="mt-6 flex justify-between">
              <Button onClick={onCloseModal} color="gray" className="w-full sm:w-auto">
                Cancel
              </Button>
              <Button onClick={()=>dispatch(createOrder({purchasedItems : orderItems , totalPrice : total, owner : user._id}))} className="w-full sm:w-auto">
                Confirm Order
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShoppingCart;
