"use client";

import { Card } from "flowbite-react";

const Services = () => {
  return (
    <div className="p-10">
      <div>
        <h1 className="text-gray-500 dark:text-gray-400 text-4xl pb-8">
          Nos Services de Virtualisation
        </h1>

        <div className="flex justify-around p-5">
          <Card className="max-w-xs">
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
              Évaluation et Planification
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Nous commençons par une analyse approfondie de vos besoins en termes de performances et de capacité. Cette évaluation permet de sélectionner la solution de virtualisation la mieux adaptée à votre environnement. Nous planifions ensuite les ressources matérielles nécessaires, en tenant compte non seulement des exigences actuelles, mais aussi de la croissance future de votre infrastructure.
            </p>
          </Card>

          <Card className="max-w-xs">
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
              Conception de l'Architecture Virtualisée
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Une fois l’évaluation terminée, nous procédons à la conception de l’architecture virtualisée. Cela inclut la configuration des serveurs hôtes et la création des machines virtuelles (VM), en définissant soigneusement les paramètres de ressources (CPU, mémoire, stockage) et les configurations réseau nécessaires pour optimiser les performances et garantir une connectivité fluide.
            </p>
          </Card>

          <Card className="max-w-xs">
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
              Migration et Déploiement
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Lors de cette phase, nous migrons vos applications vers les nouvelles machines virtuelles. Des tests rigoureux sont effectués pour s'assurer que tout fonctionne parfaitement. Nous ajustons ensuite les ressources allouées en fonction des performances observées, afin de garantir un fonctionnement optimal des applications dans l'environnement virtualisé.
            </p>
          </Card>

          <Card className="max-w-xs">
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
              Gestion et Maintenance
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Après le déploiement, nous assurons une gestion continue de l'infrastructure virtualisée. Cela inclut la surveillance des performances des VM, la mise en place de stratégies de sauvegarde et de reprise après sinistre, ainsi que la planification des mises à jour régulières pour garantir la sécurité et l’évolutivité de votre infrastructure. Nous nous engageons à maintenir vos systèmes à jour et à prévenir toute interruption de service.
            </p>
          </Card>
        </div>

        <p className="text-gray-500 dark:text-gray-400 px-40 text-center leading-10">
          Avec ces services, CONFIDOO vous accompagne à chaque étape pour maximiser l'efficacité de votre infrastructure IT grâce à la virtualisation, tout en assurant une gestion proactive et une évolutivité continue.
        </p>
      </div>

      <div>
        <h1 className="text-gray-500 dark:text-gray-400 text-4xl pb-8">
          Nos services de Consulting et Maintenance Technologique
        </h1>
        <p className="text-gray-500 dark:text-gray-400 leading-8">
          Nous comprenons que chaque organisation a des exigences spécifiques, c’est pourquoi nous proposons des solutions personnalisées pour optimiser vos systèmes, assurer leur performance continue, et vous aider à maintenir une longueur d'avance dans un environnement numérique en constante évolution.
        </p>

        <div className="flex justify-around gap-1 py-5 px-24 flex-wrap">
          <Card className="max-w-96">
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
              Évaluation Personnalisée des Infrastructures IT
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Nous débutons par une évaluation complète de votre infrastructure IT actuelle. Nos consultants expérimentés examinent vos systèmes existants, identifient les goulots d'étranglement et les domaines à fort potentiel d'amélioration pour vous fournir des recommandations adaptées à vos objectifs.
            </p>
          </Card>

          <Card className="max-w-96">
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
              Implémentation de Solutions Technologiques Innovantes
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Nous vous conseillons sur les solutions technologiques les mieux adaptées à vos besoins spécifiques, qu'il s'agisse de nouvelles plateformes logicielles, de solutions cloud, ou de matériel de pointe. Nos experts assurent une implémentation fluide, dans le respect des délais et du budget.
            </p>
          </Card>

          <Card className="max-w-96">
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
              Gestion de Projets IT Complexes
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Nos gestionnaires de projet IT pilotent vos projets technologiques de bout en bout. Grâce à une gestion proactive des risques, une coordination efficace des équipes, et une communication transparente, nous veillons à la réussite de chaque projet.
            </p>
          </Card>

          <Card className="max-w-96">
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
              Optimisation des Processus et Automatisation
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Nous analysons vos processus métiers pour identifier les opportunités d'automatisation et d'amélioration. Qu'il s'agisse de l'optimisation des flux de travail ou de l'automatisation des tâches répétitives, nos solutions sont conçues pour accroître l'efficacité opérationnelle et réduire les coûts.
            </p>
          </Card>

          <Card className="max-w-96">
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
              Formation Continue et Support Technique
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Après l’implémentation des nouvelles technologies, nous assurons une formation complète pour garantir une adoption efficace par vos équipes. Nous offrons également un support technique continu pour maintenir la performance de vos systèmes et protéger vos investissements.
            </p>
          </Card>

          <Card className="max-w-96">
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
              Maintenance Proactive
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Nos services de maintenance garantissent que vos systèmes restent opérationnels en permanence. Grâce à une surveillance proactive, nous identifions et résolvons les problèmes avant qu'ils n'affectent vos opérations, minimisant ainsi les temps d'arrêt.
            </p>
          </Card>
        </div>

        <p className="text-gray-500 dark:text-gray-400 px-40 text-center leading-10">
          Avec ces services, CONFIDOO vous accompagne à chaque étape pour maximiser l'efficacité de votre infrastructure IT grâce à la virtualisation, tout en assurant une gestion proactive et une évolutivité continue.
        </p>
      </div>
    </div>
  );
};

export default Services;
