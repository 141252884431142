import { Route, Routes,} from 'react-router-dom';
import './App.css';
import GlobalFooter from './Components/GlobalFooter';
import Home from './Components/Home';
import Nav from './Components/Nav';
import Products from './Components/Products';

import Orders from './Components/Orders';

import Solutions from './Components/Solutions';

import Services from './Components/Services';
import ShoppingCart from './Components/ShoppingCart';
import Consulting from './Components/Consulting';

function App() {
  
  

  return (
    <div>
      <Nav />
      
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Solutions" element={<Solutions/>} />
          <Route path="/Products" element={<Products />} />
          
          <Route path="/Services" element={<Services/>} />
          <Route path="/ShoppingCart" element={<ShoppingCart/>} />
        
          <Route path='/Consulting' element={<Consulting></Consulting>}></Route>
          <Route path="/Orders" element={<Orders/>} />
         
          
        </Routes>
      
      <GlobalFooter />
    </div>
  );
}

export default App;
