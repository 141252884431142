import * as React from 'react';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

// Colors from your logo
const logoColors = {
  top: '#00acb9',    // Teal
  right: '#065587',  // Dark Blue
  bottom: '#f15b39', // Orange
  left: '#01acbb',   // Light Teal
};

// Keyframes for rotating gradient border animation
const rotatingGradientBorderAnimation = `
  @keyframes rotateGradientBorder {
    0% {
      background: linear-gradient(45deg, ${logoColors.top}, ${logoColors.right}, ${logoColors.bottom}, ${logoColors.left});
    }
    25% {
      background: linear-gradient(45deg, ${logoColors.left}, ${logoColors.top}, ${logoColors.right}, ${logoColors.bottom});
    }
    50% {
      background: linear-gradient(45deg, ${logoColors.bottom}, ${logoColors.left}, ${logoColors.top}, ${logoColors.right});
    }
    75% {
      background: linear-gradient(45deg, ${logoColors.right}, ${logoColors.bottom}, ${logoColors.left}, ${logoColors.top});
    }
    100% {
      background: linear-gradient(45deg, ${logoColors.top}, ${logoColors.right}, ${logoColors.bottom}, ${logoColors.left});
    }
  }
`;

const cardsContent = [
  {
    title: "Notre Mission",
    content:
      "Chez RFIDIA, nous sommes dédiés à transformer les défis technologiques en leviers de croissance pour nos clients. Nous proposons des solutions IT sur mesure qui optimisent et sécurisent votre parc, vos marchandises et votre personnel.",
  },
  {
    title: "Notre Expertise",
    content:
      "Depuis 2011, RFIDIA est spécialisée en technologie RFID. Nous développons des solutions clés en main telles que le Suivi Intelligent Des Actifs, la Gestion Du Stationnement, la Traçabilité Logistique, la santé, etc. Nous exploitons le potentiel de la RFID et de l’IOT pour accompagner les entreprises dans leurs projets de transformation numérique et d’automatisation.",
  },
  {
    title: "Nos Solutions",
    content:
      "RFIDIA commercialise des lecteurs, des tags et conçoit des solutions innovantes destinées aux marchés de la sécurité et de la traçabilité. Nos solutions vous fournissent des informations utiles et efficaces sur l'état ou le statut de vos produits où qu'ils se trouvent.",
  },
  {
    title: "Notre Engagement",
    content:
      "En tant que maître d'œuvre de votre solution, RFIDIA s'engage sur l'intégralité de votre projet, de l’évaluation de l’existant, conseil, POC à la conception de la solution RFID la mieux adaptée à votre contexte. Nous choisissons les composants adéquats auprès de nos partenaires fabricants de tags et de lecteurs RFID, nous développons et installons un système sur mesure.",
  },
];

const SimpleGrowAbout = () => {
  const [checked, setChecked] = React.useState(false);
  const componentRef = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setChecked(true);
        } else {
          setChecked(false);
        }
      },
      { threshold: 0.3 }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={componentRef}
      sx={{ height: 'auto', margin: '50px 0', padding: '0 16px' }}
    >
      <style>{rotatingGradientBorderAnimation}</style>
      <Typography variant="h1" align="center" sx={{ fontSize: '3rem', fontWeight: 'bold', mb: 4 }}>
        Qui sommes-nous ?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 4,
        }}
      >
        <Grow in={checked}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, justifyContent: 'center' }}>
            {cardsContent.map((card, index) => (
              <Card
                key={index}
                sx={{
                  maxWidth: 345,
                  flex: '1 1 300px',
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: '16px', // Rounded corners
                  background: 'white', // Card background
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '-4px',
                    left: '-4px',
                    right: '-4px',
                    bottom: '-4px',
                    background: `linear-gradient(45deg, ${logoColors.top}, ${logoColors.right}, ${logoColors.bottom}, ${logoColors.left})`,
                    borderRadius: '20px', // Slightly larger to account for the border
                    zIndex: 0, // Ensure the pseudo-element is visible
                    
                  },
                  '& .MuiCardContent-root': {
                    position: 'relative',
                    zIndex: 1, // Ensure the card content is above the pseudo-element
                    background: 'white', // Card content background
                    borderRadius: '12px', // Slightly smaller radius to match the card
                    margin: '4px', // Add margin to show the gradient border
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {card.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" className='h-60'>
                    {card.content}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grow>
      </Box>
    </Box>
  );
};

export default SimpleGrowAbout;