import { Typography, Box, Container } from '@mui/material';

const Solutions = () => {
  return (
    <div className="p-10 bg-white dark:bg-gray-900">
      {/* Section: Ce que nous faisons */}
      <div>
        <h1 className="text-[#065587] dark:text-[#00acb9] text-4xl font-bold pb-8">Ce que nous faisons</h1>
        <p className="text-gray-600 dark:text-gray-400 leading-8">
          Chez RFIDIA, nous proposons des solutions RFID complètes adaptées à vos besoins. Notre expertise en technologie RFID, combinée à notre connaissance du secteur, nous permet de fournir des solutions personnalisées qui répondent à vos défis spécifiques. De la mise en œuvre matérielle et logicielle au support continu et à l'intégration du système, nous proposons des solutions de bout en bout pour optimiser vos processus de gestion d'actifs.
        </p>
      </div>

      {/* Section: Matériel, Logiciel, Services */}
      <div className="container mx-auto px-28 py-12">
        <div className="flex gap-8">
          {/* Matériel Card */}
          <div className="bg-[#f15b39] p-6 rounded-lg shadow-lg text-white">
            <h1 className="text-xl font-bold mb-4">Matériel</h1>
            <ul className="list-disc list-inside">
              <li>Vaste gamme de matériel RFID composée d'étiquettes robustes, de lecteurs avancés, d'antennes et d'imprimantes puissantes.</li>
            </ul>
          </div>

          {/* Logiciel Card */}
          <div className="bg-[#00acb9] p-6 rounded-lg shadow-lg text-white">
            <h1 className="text-xl font-bold mb-4">Logiciel</h1>
            <ul className="list-disc list-inside">
              <li>Logiciel– RFIDIA – suivi en temps réel avec des analyses approfondies pour des opérations optimisées.</li>
            </ul>
          </div>

          {/* Services Card */}
          <div className="bg-[#065587] p-6 rounded-lg shadow-lg text-white">
            <h1 className="text-xl font-bold mb-4">Services</h1>
            <ul className="list-disc list-inside">
              <li>Services RFID allant du conseil à la conception de solutions, en passant par l'installation, les intégrations, la formation et le support.</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Section: Pourquoi RFIDIA */}
      <div>
        <h1 className="text-[#065587] dark:text-[#00acb9] text-4xl font-bold pb-8">Pourquoi RFIDIA</h1>
        <p className="text-gray-600 dark:text-gray-400 leading-8">
          Nous choisir comme fournisseur de solutions RFID vous offre les avantages suivants :
        </p>
        <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 mt-4 space-y-2">
          <li>Technologie RFID de pointe adaptée à vos besoins uniques</li>
          <li>Une vaste expertise et expérience du secteur</li>
          <li>Intégration transparente avec les systèmes et processus existants</li>
          <li>Des solutions fiables et évolutives pour les entreprises</li>
          <li>Un service client exceptionnel et un service dédié</li>
          <li>Antécédents prouvés de mises en œuvre réussies</li>
        </ul>
      </div>

      {/* Section: Géolocalisation et traçabilité */}
      <div className="mt-12">
        <h1 className="text-[#065587] dark:text-[#00acb9] text-4xl font-bold pb-8">Géolocalisation et traçabilité en temps réel grâce à RFIDIA Assets Tracking</h1>

        {/* Pourquoi gérer son parc */}
        <div className="mb-8">
          <h2 className="text-[#f15b39] text-2xl font-bold mb-4">Pourquoi gérer son parc ?</h2>
          <p className="text-gray-600 dark:text-gray-400 leading-8">
            Faire l’inventaire permet de dresser une cartographie précise de son patrimoine mais uniquement à un instant donné. Le système de gestion des actifs traditionnel est sujet à des imprécisions dues à l'utilisation de codes-barres. Selon la taille de votre établissement, vous pouvez disposer d'un grand nombre d'actifs fixes et mobiles. Chaque actif doit être doté d'un code-barres qui ne peut être facilement scanné soit à cause de son emplacement inaccessible où car il est endommagé et retiré facilement, ce qui rend votre suivi d'inventaire inexact ou plus laborieux. En outre, les actifs sont disponibles dans une grande variété de types de surfaces et ne sont pas toujours compatibles avec les codes-barres. La RFID peut surmonter tous ces obstacles. Il existe un grand nombre d'étiquettes conçues pour n'importe quelle surface et, comme la numérisation est automatique, l'étiquette n'a pas besoin d'être placée dans un endroit visible et facilement endommageable.
          </p>
        </div>

        {/* Pourquoi RFIDIA */}
        <div className="mb-8">
          <h2 className="text-[#f15b39] text-2xl font-bold mb-4">Pourquoi RFIDIA ?</h2>
          <p className="text-gray-600 dark:text-gray-400 leading-8">
            Gérez même vos plus petits actifs avec une solution RFID sur mesure. Notre solution avancée vous aide à réduire les actifs perdus ou égarés, à automatiser les audits d'inventaire et à améliorer l'utilisation des actifs. Optimisez vos opérations avec la technologie RFID pour un suivi et une gestion précis des actifs. Grâce à une visibilité en temps réel sur les emplacements des actifs et à des historiques d'audit détaillés, vous pouvez rationaliser les processus de maintenance et optimiser l'allocation des ressources. La puissance de la technologie RFID transforme vos pratiques de gestion des actifs. Avec RFIDIA. … D’un seul « balayage » il est possible de lire tous les tags RFID de toute une pièce.
          </p>
        </div>

        {/* Avantages */}
        <div className="mb-8">
          <h2 className="text-[#f15b39] text-2xl font-bold mb-4">Avantages :</h2>
          <p className="text-gray-600 dark:text-gray-400 leading-8">
            Multiples secteurs, multiples avantages. La solution RFIDIA-Assets tracking présente de nombreux avantages dont tirent parti de nombreux acteurs. Elle va répondre par exemple aux besoins suivants :
          </p>
          <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 mt-4 space-y-2">
            <li>Avoir une meilleure connaissance des stocks pour éviter les pertes et les vols d’outils et matériels spécifiques souvent onéreux.</li>
            <li>Améliorer la gestion du parc d’équipement et des flux logistiques internes et externes.</li>
            <li>Obtenir des statistiques précises sur l’utilisation des équipements et des matériels.</li>
            <li>Anticiper les prochaines maintenances à réaliser et ainsi améliorer les conditions de travail des salariés.</li>
            <li>Intégration, compatibilité et connectivité. Les systèmes existants tels que les progiciels de gestion intégrés (ERP) et la gestion d'entrepôt s'intègrent parfaitement à RFIDIA, garantissant ainsi l'exactitude des données et une efficacité améliorée grâce à l'interopérabilité des systèmes.</li>
          </ul>
        </div>

        {/* Comment ça marche */}
        <div className="mb-8">
          <h2 className="text-[#f15b39] text-2xl font-bold mb-4">Comment ça marche</h2>
          <p className="text-gray-600 dark:text-gray-400 leading-8">
            Une étiquette RFID est apposée sur chaque article pour son identification. Il existe des étiquettes pour tous les types de surfaces, y compris le métal, le plastique, le tissu et même des articles spécifiques tels que les bijoux et les lunettes. Un employé peut alors parcourir les allées des articles, avec un Lecteur RFID mobile, et effectuer automatiquement l'inventaire en marchant à portée des articles à inventorier. Le lecteur RFID a une portée d’environ 5 mètres, ce qui est beaucoup plus loin qu'un lecteur de codes-barres conventionnel, et suit automatiquement l'emplacement et l'inventaire de chaque article simplement en marchant à portée, aucune numérisation manuelle de chaque article n'est nécessaire. Cela améliore considérablement la précision et l'efficacité par rapport aux méthodes conventionnelles et réduit les dommages car les articles n'ont pas besoin d'être manipulés par l'employé pour les scanner.
          </p>
        </div>
      </div>

      {/* Call to Action */}
      <div className="mt-12 text-center">
        <p className="text-gray-600 dark:text-gray-400 leading-8">
          Planifiez une démonstration d'application en direct et découvrez le suivi des actifs RFID en temps réel, les analyses avancées et bien plus encore. Réservez votre démonstration personnalisée dès aujourd'hui.
        </p>
      </div>

      {/* Section: Gestion logistique et entrepôt */}
      <div className="mt-12">
        <h1 className="text-[#065587] dark:text-[#00acb9] text-4xl font-bold pb-8">Gestion logistique et entrepôt</h1>
        <div className="mb-8">
          <h1 className="text-gray-500 dark:text-gray-400 text-4xl pb-8">
            RFIDIA- warehouse ®: la meilleure façon d’assurer le contrôle et le suivi des flux logistiques
          </h1>
          <p className="text-gray-600 dark:text-gray-400 leading-8">
            Contrairement aux systèmes de collecte de données fondés sur les codes à barres, La technologie RFID, plus « intelligents » , facile à utiliser et parfaitement adaptée aux opérations automatisées. Elle combine des avantages que l’on ne retrouve pas dans d’autres technologies d’identification. La RFID ne requiert aucun contact ni visibilité directe entre le lecteur et l’objet à identifier. Elle fonctionne dans des environnements rudes, permet la lecture simultanée de plusieurs étiquettes et fournit un haut niveau d’intégrité des données.
          </p>

          <h1 className="text-gray-500 dark:text-gray-400 text-3xl pb-8">
            6 BONNES RAISONS D'ADOPTER LA SOULUTION RFIDIA- warehouse ®:
          </h1>

          <p className="text-gray-600 dark:text-gray-400 leading-5">
            Dans l’optique de profiter pleinement du potentiel offert par la technologie RFID, notre équipe d’expert vous expose les principaux avantages de cette technologie appliqués à un environnement logistique.
          </p>

          <h1 className="text-gray-500 dark:text-gray-400 text-xl py-8">
            RFIDIA- warehouse : permet d’obtenir une information en temps réel
          </h1>

          <div>
            {/* Mise en stock */}
            <div className="mb-8">
              <h2 className="text-[#f15b39] text-2xl font-bold mb-4">Mise en stock</h2>
              <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 mt-4 space-y-2">
                <li>Suppression des écarts entre le stock Théorique et le stock réel</li>
                <li>Contrôle de cohérence en temps réel des articles à préparer</li>
                <li>Enregistrement immédiat, automatique et fiable des sorties de stock</li>
                <li>Géolocalisation des marchandises et préparation de commandes</li>
                <li>Comptage automatique sans ressaisie des sorties de stock</li>
                <li>Contrôle et suivi de bacs, conteneurs, palettes avec rotation, en circuit ouvert et fermé</li>
              </ul>
            </div>

            {/* Préparation des commandes */}
            <div className="mb-8">
              <h2 className="text-[#f15b39] text-2xl font-bold mb-4">Préparation des commandes</h2>
              <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 mt-4 space-y-2">
                <li>Lecture groupée et automatisée des articles à stocker</li>
                <li>Optimisation des manutentions, suppression des pertes de temps pour la recherche d’articles</li>
                <li>Retour en temps réel sur la disponibilité des alvéoles de stockage</li>
                <li>Optimisation du temps des opérateurs dans leurs missions</li>
                <li>Guidage des opérateurs dans les préparations de commandes</li>
              </ul>
            </div>

            {/* Expédition */}
            <div className="mb-8">
              <h2 className="text-[#f15b39] text-2xl font-bold mb-4">Expédition</h2>
              <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 mt-4 space-y-2">
                <li>Monitoring et historisation des expéditions</li>
                <li>Réduction des écarts de livraison, amélioration de la satisfaction client</li>
                <li>Vérification et validation automatique et immédiate de la conformité de la commande à expédier</li>
              </ul>
            </div>

            {/* Audit et traitement de données */}
            <div className="mb-8">
              <h2 className="text-[#f15b39] text-2xl font-bold mb-4">Audit et traitement de données</h2>
              <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 mt-4 space-y-2">
                <li>Planification, Ordonnancement Décisionnels</li>
                <li>Suivi du flux des stocks, analyse qualitative et quantitative</li>
                <li>Traitement des données en temps réel (relevé des indicateurs, statistiques, alerte) permettant la mise en place d’actions correctives</li>
              </ul>
            </div>

            {/* Réduction des erreurs logistiques */}
            <div className="mb-8">
              <h2 className="text-[#f15b39] text-2xl font-bold mb-4">Réduction des erreurs logistiques</h2>
              <p>Par ailleurs, RFIDIA- warehouse vise à éliminer toute forme d’erreurs, quelle que soit sa nature :</p>
              <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 mt-4 space-y-2">
                <li>Les erreurs d’identification produit</li>
                <li>Les erreurs de comptage</li>
                <li>Les erreurs administratives comme les DLC dépassées ou une expédition incomplète / erronée</li>
              </ul>
            </div>

            {/* Traçabilité renforcée */}
            <div className="mb-8">
              <h2 className="text-[#f15b39] text-2xl font-bold mb-4">Traçabilité renforcée</h2>
              <p>
                Enfin, l’identification par radio-fréquence vous permettra de retrouver aisément tout l’historique du cheminement de chaque étiquette RFID. Effectivement, cette dernière est scannée à chaque étape. En assurant ainsi une excellente traçabilité de vos marchandises, vous parviendrez à lutter efficacement contre le vol.
              </p>

              <h3>MISE EN PLACE SIMPLIFIÉE DE RFIDIA- warehouse</h3>
              <ul className="list-decimal list-inside text-gray-600 dark:text-gray-400 mt-4 space-y-2">
                <li>
                  Pose des étiquettes RFID sur des produits ou des conteneurs (palettes, caisses, fils…) effectuée par des imprimantes ou des systèmes de dépose automatique pour identifier chaque lot. Chaque produit devient unique.
                </li>
                <li>
                  Installation de systèmes de capture d’informations RFID (Lecteurs RFID, Terminaux mobiles, mâts, portiques, tunnels …) en entrée/sorties des bâtiments industriels et entrepôts pour recueillir automatiquement les données consolidées.
                </li>
                <li>
                  Intégration dans le middleware RFIDIA-warehouse : collectant et historisant toutes les données des lecteurs RFID fixes ou mobiles. Compatibilité avec tous systèmes d’informations (ERP, WMS, WCS) et avec votre environnement d’automatismes.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;