"use client";


import React, {  useEffect, useState } from "react";
import { Blockquote, Button, Card, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import { useSelector } from "react-redux";

const products = [
  
  {
    id: 1,
    name: "Desktop RFID UHF Card",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/PGzxF0r4/RA-61.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 2,
    name: "USB Desktop Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/MDtStCK6/RA-67.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 3,
    name: "long range Android UHF RFID handheld reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/dwkW9C8d/RA-81.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 4,
    name: "Bluetooth UHF RFID Handheld Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/V0fKDP2c/RA-88Pro.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 5,
    name: "9dBi UHF RFID Antenna",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/F4XjHhWH/RA-993R.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 6,
    name: "Vertical access control Antenna data Main door + auxiliary door",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/dZkvd1W/RA-1542-TP.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 7,
    name: "12dBi UHF RFID Antenna",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/WNSPY82v/RA-9121V.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 8,
    name: "Tablet RFID",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/60GN9XzD/RA-C2515.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 9,
    name: "Bluetooth UHF RFID Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/WW1LRr5G/RA-CL108.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 10,
    name: "Charging Cradle",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/rGc8yHbg/RA-CL108-D.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 11,
    name: "Integrated Intelligent RFID Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/k69QgWKv/RA-CL203.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 12,
    name: "Lecteur CSL-RFID CS203X-2 RFID to check",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/W4sNZNtY/RA-CL203-1.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 13,
    name: "Powered RFID Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/LDHRdRrX/RA-CL463.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 14,
    name: "Bluetooth UHF RFID Sled Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/kVd6XNGv/RA-CL710-S.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 15,
    name: "UHF RFID Antenna",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/Qv97RBs7/RA-CL776.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 16,
    name: "Brickyard Near Field UHF Antenna",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/N6yWLBfx/RA-CL777.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 17,
    name: "LED RFID Tag",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/xSGgLSMv/RA-CL6861.png",
    details: "some description",
    family: "TAG"
  },
  {
    id: 18,
    name: "Top mounted RFID access control Product",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/fY3BkMV3/RA-DZ1020-R.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 19,
    name: "Ceiling RFID reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/d0rykDF7/RA-DZ5050.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 20,
    name: "Antenne UHF",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/fdFg47gT/RA-INV11.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 21,
    name: "Antenne UHF À Gain Élevé",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/1prBymR/RA-INV26.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 22,
    name: "Antenne UHF LINEAIRE",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/LzD4LHgP/RA-INV35.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 23,
    name: "Lecteur bureau RFID",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/PsfJD2MF/RA-INV739.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 24,
    name: "Lecteur bureau RFID",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/ZRQxDGzY/RA-INV812.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 25,
    name: "Lecteurs intégré",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/KzbNrfzc/RA-INV850.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 26,
    name: "Lecteur bureau RAlN RFID",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/fdXWCQSf/RA-INV855.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 27,
    name: "Linux Lecteur RFID",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/C5yXFsfF/RA-INV861.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 28,
    name: "loT Smart Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/B22Skb2T/RA-INV868.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 29,
    name: "Lecteur portable UHF",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/BVF3YtTz/RA-INV2908.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 30,
    name: "Animal tag RFID reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/2YPJ5Bs0/RA-L2615.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 31,
    name: "UHF RFID Smartphone Handheld Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/N2WBfzfS/RA-P12.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 32,
    name: "UHF antenna 9dBi (waterproof)",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/pvwHjnPC/RA-Q256R.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 33,
    name: "impinj speedway revolution rfid reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/xN4nsNY/RA-R220.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 34,
    name: "impinj speedway revolution rfid reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/99yWmtdk/RA-R420.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 35,
    name: "xSpan RFID Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/xqpqv3bK/RA-R660.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 36,
    name: "Impinj READER",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/Y4bJN2HM/RA-R700.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 37,
    name: "Impinj Angle Large Label READER",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/4wCx0pjg/RA-R720.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 38,
    name: "Impinj xPortal",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/wFzpDdqy/RA-RX6.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 39,
    name: "xArray IMPINJ",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/HpxXgZJb/RA-RxAr.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 40,
    name: "10.1-inch car tablet Specification sheet",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/zVWvw3FT/RA-V10T.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 41,
    name: "UHF RFID integrated Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/QjJzShm7/RA-VH83S.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 42,
    name: "ultra high frequency Security access control",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/rGg68K8H/RA-VH410-TP.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 43,
    name: "UHF vertical channel access control",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/9mMnQZX1/RA-VH460.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 44,
    name: "4-Port UHF RFID Fixed Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/DHzLSp26/RA-VH747-P.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 45,
    name: "UHF RFID Industrial Reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/k24fnccF/RA-VHIR600.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 46,
    name: "RA-VHZ09",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://i.ibb.co/h1m7cWJr/RA-VHZ09.png",
    details: "some description",
    family: "READER"
  },
  
  {
    id: 47,
    name: "Pistolets de support RFID UHF",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 48,
    name: "Terminal transportable Android",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 49,
    name: "Pistolet de support RFID UHF ultra-durci",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 50,
    name: "Boîtier RFID/1D/2D portable",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "MOBILE"
  },
  {
    id: 51,
    name: "Lecteur RFID pour point de vente",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 52,
    name: "Lecteurs RFID fixes ultra-durcis",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 53,
    name: "Lecteur RFID fixe",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 54,
    name: "Lecteur RFID fixe",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 55,
    name: "Lecteur de transition",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "READER"
  },
  {
    id: 56,
    name: "Antenne RFID pour l’arrière-boutique et l’entrepôt",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 57,
    name: "Antenne RFID pour points de vente",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 58,
    name: "ANTENNE de vente au détail Capteur Sensing",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 59,
    name: "Antennes RFID à large bande",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 60,
    name: "Antennes RFID à large bande",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 61,
    name: "UHF RFID Adhesive Wet Inlay Alien H3/H4,Monza 4/5,UCODE 7",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ANTENNE"
  },
  {
    id: 62,
    name: "Tag NFC pour Téléphone android RGC1000042",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "NFC"
  },
  {
    id: 63,
    name: "Affiche de NFC sur mesure pour la publicité RGC1000050",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "NFC"
  },
  {
    id: 64,
    name: "Affiche intelligente PVC NFC avec adhésif",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "NFC"
  },
  {
    id: 65,
    name: "NFC Tag epoxy",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "NFC"
  },
  {
    id: 66,
    name: "tag nfc imprimable de paiement",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "NFC"
  },
  {
    id: 67,
    name: "Bague nfc",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "NFC"
  },
  {
    id: 68,
    name: "HF NFC Sticker Tag",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "NFC"
  },
  {
    id: 69,
    name: "Adhésif autocollant NFC",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "NFC"
  },
  {
    id: 70,
    name: "Tag NFC avec QR code pour bracelet nfc",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "NFC"
  },
  {
    id: 71,
    name: "Tag nfc puce Ntag213 époxy",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "NFC"
  },
  {
    id: 72,
    name: "Porte clés avec revêtement époxy NFC",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "NFC"
  },
  {
    id: 73,
    name: "Alien HiScan RFID White Wet Inlay",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ETIQUETTE"
  },
  {
    id: 74,
    name: "Short RFID White Wet Inlay",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ETIQUETTE"
  },
  {
    id: 75,
    name: "Smartrac Belt RFID Wet Inlay",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ETIQUETTE"
  },
  {
    id: 76,
    name: "Smartrac DogBone RFID Paper Tag",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ETIQUETTE"
  },
  {
    id: 77,
    name: "Alien Short RFID White Wet Inlay",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ETIQUETTE"
  },
  {
    id: 78,
    name: "Etiquette de pare-brise RFID UHF",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ETIQUETTE"
  },
  {
    id: 79,
    name: "ETIQUETTE RFID UHF INVENTAIRE",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ETIQUETTE"
  },
  {
    id: 80,
    name: "Etiquette RFID UHF",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ETIQUETTE"
  },
  {
    id: 81,
    name: "Etiquette RFID UHF",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ETIQUETTE"
  },
  {
    id: 82,
    name: "Etiquette RFID UHF",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "ETIQUETTE"
  },
  {
    id: 83,
    name: "Barrière Automatique Pour Lisse Jusqu’À 6 M Avec Structure Laquée. Centrale De Commande, Récepteur Radio",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "SECURE"
  },
  {
    id: 84,
    name: "Barrière Automatique Pour Lisse Jusqu’À 6 M",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "SECURE"
  },
  {
    id: 85,
    name: "Tour de signalisation lumineuse",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "SECURE"
  },
  {
    id: 86,
    name: "UHF integrated reader",
    price: 0,
    description: "some description",
    rating: 0,
    image: "https://dynastyenterprise.websites.co.in/twenty-nineteen/img/defaults/product-default.png",
    details: "some description",
    family: "READER"
  }
]; 


const Products = () => {
  const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem("Cart")) || []);
  const [visibleItems, setVisibleItems] = useState(12);
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(products);

  // Access the selected category from the Redux store
  const selectedCategory = useSelector((state) => state.categoryReducer.category);

  useEffect(() => {
    localStorage.setItem("Cart", JSON.stringify(cartItems));
  }, [cartItems]);

  // Filter products whenever searchTerm, selectedCategories, or selectedCategory changes
  useEffect(() => {
    filterProducts(searchTerm, selectedCategories, selectedCategory);
  }, [searchTerm, selectedCategories, selectedCategory]);

  const handleAddRemoveCart = (product) => {
    if (cartItems.find((item) => item.id === product.id)) {
      setCartItems((prevCartItems) => prevCartItems.filter((item) => item.id !== product.id));
    } else {
      setCartItems((prevCartItems) => [...prevCartItems, product]);
    }
  };

  const handleShowMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 12);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCategoryChange = (category) => {
    const updatedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter((c) => c !== category)
      : [...selectedCategories, category];
    setSelectedCategories(updatedCategories);
  };

  const filterProducts = (searchTerm, categories, selectedCategory) => {
    let filtered = products;

    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Filter by selected categories (from modal)
    if (categories.length > 0) {
      filtered = filtered.filter((product) =>
        categories.includes(product.family)
      );
    }

    // Filter by selected category (from Redux)
    if (selectedCategory && selectedCategory !== "ALL") {
      filtered = filtered.filter((product) =>
        product.family === selectedCategory
      );
    }

    setFilteredProducts(filtered);
    setVisibleItems(12); // Reset visible items when filters change
  };

  const resetFilters = () => {
    setSearchTerm("");
    setSelectedCategories([]);
    setFilteredProducts(products);
    setVisibleItems(12);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header and Search Bar */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-[#075485] mb-4">Our Products</h1>
        <Blockquote className="text-gray-600 text-lg max-w-2xl mx-auto">
          "Explore our wide range of innovative IT and RFID solutions designed to enhance your business operations. From cutting-edge hardware to advanced software, we provide reliable products tailored to meet your organization's unique needs."
        </Blockquote>
      </div>

      {/* Search and Filter Section */}
      <div className="mb-8">
        <div className="max-w-md mx-auto flex items-center gap-2">
          <Button
            onClick={() => setOpenModal(true)}
            className="bg-[#075485] hover:bg-[#00a7b9] text-white p-1"
          >
            <svg
              className="w-5 h-5 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M5.05 3C3.291 3 2.352 5.024 3.51 6.317l5.422 6.059v4.874c0 .472.227.917.613 1.2l3.069 2.25c1.01.742 2.454.036 2.454-1.2v-7.124l5.422-6.059C21.647 5.024 20.708 3 18.95 3H5.05Z" />
            </svg>
          </Button>

          <form className="flex-1">
            <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search Products"
                value={searchTerm}
                onChange={handleSearchChange}
                required
              />
            </div>
          </form>
        </div>
      </div>

      {/* Filter Modal */}
      <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)}>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900">Filter Products</h3>

            <div className="flex flex-col gap-2">
              {["ANTENNE", "ETIQUETTE", "MOBILE", "NFC", "READER", "TAG"].map((category) => (
                <div key={category} className="flex items-center gap-2">
                  <Checkbox
                    checked={selectedCategories.includes(category)}
                    onChange={() => handleCategoryChange(category)}
                  />
                  <Label>{category}</Label>
                </div>
              ))}
            </div>

            <div className="flex justify-between">
              <Button
                onClick={resetFilters}
                className="bg-[#b63123] hover:bg-[#8a241a] text-white"
              >
                Reset Filters
              </Button>
              <Button
                onClick={() => setOpenModal(false)}
                className="bg-[#075485] hover:bg-[#00a7b9] text-white"
              >
                Save Preferences
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Product Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {filteredProducts.slice(0, visibleItems).map((product) => (
          <Card key={product.id} className="hover:shadow-lg transition-shadow duration-300">
            <div className="flex flex-col justify-between h-full">
              <img
                src={product.image}
                alt={product.name}
                className="h-48 w-full object-contain mb-4"
              />

              <h5 className="text-xl font-semibold text-[#075485]">{product.name}</h5>

              <div className="flex items-center justify-between mt-4">
                <span className="text-2xl font-bold text-[#f35a37]">{product.price}TND</span>
                <button
                  className={`px-4 py-2 rounded-lg font-medium transition-colors duration-300 ${
                    cartItems.find((item) => item.id === product.id)
                      ? "bg-[#b63123] text-white hover:bg-[#8a241a]"
                      : "bg-[#00a7b9] text-white hover:bg-[#075485]"
                  }`}
                  onClick={() => handleAddRemoveCart(product)}
                >
                  {cartItems.find((item) => item.id === product.id) ? "Remove" : "Add to Cart"}
                </button>
              </div>
            </div>
          </Card>
        ))}
      </div>

      {/* Show More Button */}
      {visibleItems < filteredProducts.length && (
        <div className="flex justify-center mt-8">
          <Button
            gradientMonochrome="info"
            onClick={handleShowMore}
            className="bg-[#075485] hover:bg-[#00a7b9] text-white"
          >
            Show More
          </Button>
        </div>
      )}
    </div>
  );
};

export default Products;