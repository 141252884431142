import axios from 'axios'
import { GETALLORDERS, GETUSERORDERS } from '../ActionTypes/ordersTypes';
import { handleError } from './errorActions';

export const getallorders = ()=>async(dispatch)=>{
    try {
        const res = await axios.get('/api/orders/getOrders')
        dispatch(
            {
                type : GETALLORDERS,
                payload : res.data.orders
            }
        )
    } catch (error) {        
            error.response.data.errors.forEach(element => {
        
            dispatch(handleError(element.msg))
                    
        });
            
    }

}


export const createOrder =(order)=>async(dispatch)=>{

    try {

        await axios.post('/api/orders/createOrder',order)
        dispatch(
            getallorders()
        )
    } catch (error) {

        error.response.data.errors.forEach(element => {
        
            dispatch(handleError(element.msg))
                    
        });
        
    }

}

export const getuserorders =(id)=>async(dispatch)=>{

    try {
        const res = await axios.get(`/api/orders/getOrdersbyUser/${id}`)

        dispatch(
            {
                type : GETUSERORDERS ,
                payload : res.data.orders
            }
        )
        
    } catch (error) {
        error.response.data.errors.forEach(element => {
        
            dispatch(handleError(element.msg))
                    
        });
    }

}

export const cancelOrder = (orderID , userID)=>async(dispatch)=>{
    try {
        await axios.delete(`/api/orders/deleteOrder/${orderID}`)

        dispatch(getuserorders(userID))
    } catch (error) {
        
    }
}