import React, { useEffect } from 'react'

import{useDispatch, useSelector} from 'react-redux'
import { getallorders } from '../Redux/Actions/ordersActions'
import OrdersCard from './OrdersCard'

const Orders = () => {

  const orders = useSelector(state=>state.ordersReducer.orders)

  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getallorders())
  },[])
  return (
    <div>{
      orders.map((el,i,t)=><OrdersCard el ={el}></OrdersCard>)
      }</div>
  )
}

export default Orders