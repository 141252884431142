import React from 'react';
import { Typography, Box, Container } from '@mui/material';

const Activity = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4, px: { xs: 2, sm: 4, md: 6 } }}>
      {/* Section 1: Notre activité */}
      <Box sx={{ mb: 6 }}>
      <h1 className="text-gray-500 dark:text-gray-400 text-4xl pb-8">
      Notre activité
        </h1>
        
        <Typography
          variant="body1"
          sx={{
            color: '#333',
            lineHeight: 1.6,
          }}
        >
          Depuis plus de 14 ans, nous accompagnons les entreprises dans leurs projets de transformation numérique et d’automatisation. Expert en solutions traçabilité, nous développons des logiciels et RFID pour la gestion de production, la gestion des inventaires, le transport et la logistique et le suivi des opérations de maintenance. Ses solutions permettent aux entreprises de réduire leurs coûts et de gagner en agilité grâce à :
          <ul className="list-disc pl-8 mt-2">
            <li>La sécurisation des flux logistiques</li>
            <li>L’automatisation les tâches chronophages et sujettes aux erreurs</li>
          </ul>
          Nous accompagnons chaque client au cas par cas et développe des solutions surmesure, adaptées à son environnement et à ses contraintes propres.
        </Typography>
      </Box>

      {/* Section 2: Notre savoir-faire */}
      <Box sx={{ mb: 6 }}>
      <h1 className="text-gray-500 dark:text-gray-400 text-4xl pb-8">
      Notre savoir-faire
        </h1>
       
        <Typography
          variant="body1"
          sx={{
            color: '#333',
            lineHeight: 1.6,
          }}
        >
          Notre savoir-faire est basé à la fois sur les compétences de nos équipes de développeurs et techniciens mais également sur notre expérience depuis plus de 14 ans dans la sécurisation et l’optimisation de flux basées sur les technologies de radio-identification. Nos solutions sécurisées de niveau militaire s’intègrent parfaitement aux systèmes existants et offre un accès utilisateur illimité avec une connexion utilisateur unique. Nous fournissons également un support de mise en œuvre complet. À la pointe de l'innovation, nos solutions, développées par nos propres ressources dont les fonctionnalités sont issues de notre expérience du terrain, sont entièrement paramétrables et correspondent parfaitement avec les exigences de vos métiers ou secteurs d’activités.
        </Typography>
      </Box>

      {/* Section 3: Nos valeurs */}
      <Box sx={{ mb: 6 }}>

      <h1 className="text-gray-500 dark:text-gray-400 text-4xl pb-8">
          Nos valeurs
        </h1>
        
        <Typography
          variant="body1"
          sx={{
            color: '#333',
            lineHeight: 1.6,
          }}
        >
          <ul className="list-disc pl-8">
            <li><strong>L’innovation</strong> : Nous développons et installons des solutions RFID innovantes qui s’inscrivent déjà dans le cadre de l’industrie 4.0 avec la jonction de l’IA.</li>
            <li><strong>La fiabilité</strong> : La fiabilité de nos solutions RFID est une valeur fondamentale de notre entreprise. Nous avons le souci du respect de nos engagements vis-à-vis de nos clients et fournisseurs.</li>
            <li><strong>L’écoute</strong> : Chaque entreprise est unique et nous sommes à votre écoute pour adapter nos solutions à vos spécificités et à vos besoins.</li>
            <li><strong>La souplesse et la flexibilité</strong> : Elles sont au cœur de notre approche. De par notre expérience métier, nous connaissons les contraintes liées à chaque entreprise et à chaque secteur d’activité, et mettons en œuvre tous les moyens pour nous y adapter.</li>
          </ul>
        </Typography>
      </Box>

      {/* Section 4: Nos engagements qualité */}
      <Box sx={{ mb: 6 }}>
      <h1 className="text-gray-500 dark:text-gray-400 text-4xl pb-8">
          Nos engagements qualité
        </h1>
        <Typography
          variant="body1"
          sx={{
            color: '#333',
            lineHeight: 1.6,
          }}
        >
          RFIDIA, c’est un accompagnement sur mesure et des services adaptés à vos besoins :
          <ul className="list-disc pl-8 mt-2">
            <li>Un diagnostic et une étude technique et commerciale personnalisée,</li>
            <li>Une conception et un paramétrage de nos solutions selon vos spécifications,</li>
            <li>Un interfaçage avec votre système d’information sans adaptation de celui-ci,</li>
            <li>Une installation et un déploiement sur votre site,</li>
            <li>Une formation des utilisateurs à la solution installée,</li>
            <li>Un contrat de maintenance et une hotline téléphonique.</li>
          </ul>
        </Typography>
      </Box>
    </Container>
  );
};

export default Activity;